import React from 'react'

function ResumeFull() {
    return (
        <div className="resume_site">
            <h1>Rich Jamison</h1>
<b>Front End Web Developer</b><br />
Edmond, OK 73013<br />
tabufx50@hotmail.com<br />
405.448.6537
<br /><br />
<p>Experienced Web specialist with Front-End web development skills on both MAC and PC platforms. Strong computer literacy: extensive experience working with HTML5, CSS3, CSS Grids, jQuery, React, Visual Studio Code, SAAS, Tailwind, Bootstrap, Adobe Photoshop, Adobe XD and Wordpress. Experience with Amazon Web Services (AWS). Over 10 years experience in web production skills in fast-paced advertising environments. Extremely organized, able to multi-task many projects at a time and enjoys working with corporate teams. Manages entire production process from start to finish while maintaining excellent communication skills with production team and designers.</p>
<br />
<p>Willing to relocate: Anywhere</p>
<br />
<span>Work Experience</span><br /><br />

<p><b>Front End Developer</b><br />
SDC Inc - Edmond, OK<br />
<i>November 2018 to Present</i><br />
Full Stack developer who loves to build web apps using React, Firebase, Redux, CSS, MongoDB and Tailwind. Cool attitude, laid back vibe, easy to work with and coachable.</p>
<br />
<p><b>Marketing Manager/Web Developer</b><br />
ATKINS AND MARKOFF - Oklahoma City, OK<br />
<i>June 2017 to November 2018</i><br />
Responsible for web development for creating landing pages for Pay Per Click ad campaigns to track website traffic and conversions. Created Wordpress and HTML5 responsive websites that are mobile friendly and designed for conversions that link into CRM. Responsible for SEO efforts that increase organic traffic 400% year over year. Skills needed: HTML5, javascript, jQuery, and CSS3.
</p><br />
<p><b>Marketing Manager/Web Developer</b><br />
COMTECH - Oklahoma City, OK<br />
<i>November 2013 to June 2017</i><br />


Responsible for web development using CMS systems like Wordpress and Joomla to create dynamic and responsive website for clients. Skills needed: html5, Angular2, javascript, myphp, Apache, jQuery, and CSS3. Also responsible for marketing and advertising for clients, Search Engine Optimization, website analytics, and sales and promotion for Comtech.</p>
<br />
<p><b>Web Developer/Production Manager</b><br />
HAYES MARTIN & ASSOCIATES - Newport Beach, CA<br />
<i>2007 to January 2013</i><br />


Responsible for front-end web development including utilizing HTML5, CSS3, jQuery, Dreamweaver and Photoshop. Developed social media websites and web development for corporate clients to generate more leads and also marketing to their existing customer base. Also responsible for creating html eblasts for distribution to leads using online proprietary systems like Bronto, Exact Target and Lasso. Responsible for tracking website traffic using Google analytics to understand where traffic is coming from and where conversions are taking place. Work closely with creative department to develop advertising campaigns for major builders throughout the U.S.
</p><br />
<p><b>Education</b> <br />
Bachelor's Degree from CSUSB<br />
Platt College of Design - Ontario, CA</p><br />

<p><b>Skills</b><br />
• Javascript
• React
• Wordpress
• HTML 5
• CSS3
• CSS Grids
• Git
• Bootstrap
• AWS
• Node.js
• User Interface (UI)</p>

        </div>
    )
}

export default ResumeFull
