import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import CardsAirbnb from '../CardsAirbnb';
import CardsAmazon from '../CardsAmazon';
import CardsBio from '../CardsBio';
import CardsCovidTracker from '../CardsCovidTracker';
import NetflixCard from '../NetflixCard';
import CrownSite from '../CrownSite';
import CardsGatsby from '../CardsGatsbySite';
import CarSite from '../CarSite';
import GoogleClone from '../GoogleClone';

function Projects() {
    return (
        <>
        <GoogleClone />
        <CrownSite />
        <CardsGatsby />
        <CardsAirbnb />
        <CardsCovidTracker />
        <CardsAmazon />
        
        <NetflixCard />
        <CarSite />
        
       
        
        </>
    )
}

export default Projects;