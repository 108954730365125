import React from 'react';
import '../../App.css';
import Bio from '../Bio';
import Cards from '../Cards';

import HeroSection from '../HeroSection';
import CardsBio from '../CardsBio';
import CardsAirbnb from '../CardsAirbnb';
import CardsCovidTracker from '../CardsCovidTracker';
import CrownSite from '../CrownSite';
import NetflixCard from '../NetflixCard';
import SkillsSet from '../SkillsSet';
import CardsAmazon from '../CardsAmazon';
import CardsGatsby from '../CardsGatsbySite';
import CarSite from '../CarSite';
import GoogleClone from '../GoogleClone';

function Home() {
    return (
        <>
        <HeroSection />
        <CardsBio />
        <h2 className='my__projects'>- My Projects -</h2>
        <GoogleClone />
        <CrownSite />
        <CardsGatsby />
        <CardsAmazon />
        <CardsAirbnb />
        <CardsCovidTracker />
        
        <NetflixCard />
        <CarSite />

        <SkillsSet />


        {/* <Cards /> */}
        {/* <Footer /> */}
        </>
    )
}

export default Home;