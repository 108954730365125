import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import FeedbackForm from './FeedbackForm';




export default function Footer() {

    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_qa8w41y', 'template_l3jcpli', e.target, 'user_ASU9DaRxcquQobC6jefJD')
          .then((result) => {
              
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset();
      }

    return (
        <div className='footer-container'>
            <section className='footer-subscription'>
                <p className='footer-subscription-heading'>
                    Contact Me
                </p>
                <p className='footer-subscrition-text'>
                    Leave a message
                </p>
                <div className='input-areas'>
                    <form onSubmit={sendEmail} >
                        <input 
                        type='name' 
                        name='name'  
                        placeholder='Your Name' 
                        className='footer-input' />
                        <input 
                        type='email' 
                        name='email' 
                        placeholder='Your Email' 
                        className='footer-input' />
                         <input 
                        type='phone' 
                        name='phone' 
                        placeholder='Your Phone' 
                        className='footer-input' />
                         <input 
                        type='message' 
                        name='message' 
                        placeholder='Your Message' 
                        className='footer-input' />
                        <input className="send_button" type="submit" value="Send" id="send_button" />
                        {/* <Button type="submit" value="Send" buttonStyle='btn--outline'>Contact Me</Button> */}
                    </form>
                </div>
            </section>
            <div className='footer-links'>
                <div className='footer-link-wrapper'>
                    
                </div>
            </div>
            <section className='social-media'>
                <div className='social-media-wrap'>
                    <div className='footer-logo'>
                        <Link to='/' className='social-logo'>
                            Coder <i className='fas fa-laptop-code'></i>
                        </Link>

                        {/* <button onClick={(e) => (window.location = 'https://www.google.com')}>Click me</button> */}

                    </div>
                    <small className='website-rights'>©{new Date().getFullYear()} RichyJayCodes.com </small>
                    <div className='social-icons'>
                        
                        {/* <Link className='social-icon-link facebook'
                        to="/"
                        target="_blank"
                        aria-label='Facebook'>
                        <i className='fab fa-facebook-f'></i>
                        </Link> */}

                        <a href="http://facebook.com" className='social-icon-link facebook' target="_blank"
                        aria-label='Facebook'>
                        <i className='fab fa-facebook-f'></i>
                        </a>

                        <a href="https://github.com/tabufx50" className='social-icon-link github'
                        target="_blank"
                        aria-label='Github'>
                        <i class="fab fa-github"></i>
                        </a>

                        {/* <Link className='social-icon-link github'
                        to="/"
                        target="_blank"
                        aria-label='Github'>
                        <i class="fab fa-github"></i>
                        </Link> */}


                        <Link className='social-icon-link instagram'
                        to="/"
                        target="_blank"
                        aria-label='Instagram'
                        >
                            <i class="fab fa-instagram"></i>
                        </Link>
                    </div>
                </div>
            </section>
        
        </div>
    )
}

// export default Footer
