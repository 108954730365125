import React from 'react'

function SkillsSet() {
    return (
    <div>
            <h1 id="skills">Skills</h1>
            <p className='skillset__copy'>Here are Languages and Technologies I have learned and applied to my projects.</p> 

        <div className='container-skills'>
        
            <div className='content-skills'> 
            <div className='js__thumb1'> </div>
            <p>JavaScript</p>
            </div>

            <div className='content-skills'>
            <div className='js__thumb2'> </div>
            <p>React.js</p>
            </div>

            <div className='content-skills'>
            <div className='js__thumb3'> </div>
            <p>Redux</p>
            </div>

            <div className='content-skills'>
            <div className='js__thumb4'> </div>
            <p>Node.js</p>
            </div>

        
        </div>


        <div className='container-skills2'>

            <div className='content-skills'>
            <div className='js__thumb5'> </div>
            <p>HTML 5</p>
            </div>

            <div className='content-skills'>
            <div className='js__thumb6'> </div>
            <p>CSS 3</p>
            </div>

            <div className='content-skills'>
            <div className='js__thumb7'> </div>
            <p>Bootstrap 4</p>
            </div>

            <div className='content-skills'>
            <div className='js__thumb8'> </div>
            <p>SASS</p>
            </div>
            <div className='content-skills'>
            <div className='js__thumb22' />
            <p>MongoDB</p>
            </div>


        </div>


            <div id='h2tools'>
            <h1>Tools</h1>
                <p className='skillset__copy'>Tools I use to help create any project.</p> 
                </div>

        <div className='container-skills' id='tools'>
        
            <div className='content-skills'> 
            <div className='js__thumb10'> </div>
            <p>Visual Studio</p>
            </div>

            <div className='content-skills'>
            <div className='js__thumb11'> </div>
            <p>Git</p>
            </div>

            <div className='content-skills'>
            <div className='js__thumb12'> </div>
            <p>GitHub</p>
            </div>

            <div className='content-skills'>
            <div className='js__thumb13'> </div>
            <p>npm</p>
            </div>

        </div>




    </div>

        
        
    )
}

export default SkillsSet
