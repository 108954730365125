import React from 'react';
import '../../App.css';
import ResumeFull from '../ResumeFull';
import SkillsSet from '../SkillsSet';


export default function Resume() {
    return <>
    
    {/* <h1 className='skills'>SKILLS</h1> */}
    <ResumeFull />
    {/* <SkillsSet /> */}
    
    {/* <ContactUs /> */}
    </>;
}