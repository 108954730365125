import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

const CrownSite = () => {
    return (
        <div className='cards__container'>
         <div className='cards__wrapper2'>
         <ul className='cards__items'>
            <CardItem
                        src="images/ecom-main.jpg"
                        text="This is an Ecom site done in react."
                        label='Crown Ecom'
                        path='/projects'
                />
                        <div className='bio__copy'>
                        <h2>Crown Ecom!</h2>
                        <p>Full blown Ecom site with google authorization and 
                        ability to pay through Stripe payments. Add to cart     
                        feature that works seamlessly. Created using class components.
                        <br /><br /></p>
                        
                        <div className='circle__icon'>
                        {/* <PlayCircleFilledIcon /> */}
                        <p className="live_site">
                        <a href="https://crown-clothing-17293.web.app//" target="_blank"><span>Click Here For Live Site</span></a></p>
                        </div> 

                        
                        
                        <br />
                        <h4>Skills:</h4>
                        <ul>
                            <li>React</li>
                            <li>JavaScript</li>
                            <li>CSS</li>
                            <li>Redux</li>
                            <li>Firebase</li>
                        </ul>
                        </div>
                        
                        </ul>
            </div>
        </div>
    )
}

export default CrownSite
