import React from 'react';
import '../../App.css';
import ContactUs from '../ContactUs';
import SkillsSet from '../SkillsSet';


export default function Skills() {
    return <>
    
    {/* <h1 className='skills'>SKILLS</h1> */}
    <SkillsSet />
    {/* <ContactUs /> */}
    </>;
}