import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

const CardsCovidTracker = () => {
    return (
        <div className='cards__container'>
         <div className='cards__wrapper2'>
         <ul className='cards__items'>
            <CardItem
                        src="images/covidtracker-thumb.jpg"
                        text="This is a covid-19 tracker done in react."
                        label='Covid-19 Tracker'
                        path='/projects'
                        />
                        <div className='bio__copy'>
                        <h2>Covid-19 Tracker!</h2>
                        <p>Covid-19 tracker that pulls recent covid-19
                        data from around the world and into this app.
                        Created with React and JavaScript and CSS. You 
                        can use the search feature and see the results 
                        displayed onto the page. Click on the red circles
                        and you can see country flag and current stats.
                        You can also book a reservation using the date picker. 
                        <br /><br />
                        <div className='circle__icon'>
                        {/* <PlayCircleFilledIcon /> */}
                        <p className="live_site">
                        <a href="https://covid-19-tracker-591e5.web.app//" target="_blank"><span>Click Here For Live Site</span></a></p>
                        </div> 

                        </p>
                        <br />
                        <h4>Skills:</h4>
                        <ul>
                            <li>React</li>
                            <li>JavaScript</li>
                            <li>CSS</li>
                            <li>Axios</li>
                            <li>API</li>
                            <li>Firebase</li>
                        </ul>
                        </div>
                        
                        </ul>
            </div>
        </div>
    )
}

export default CardsCovidTracker
