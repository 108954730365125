import React, { Component } from 'react';
import './Button.css';
import Pdf from '../Documents/resume.pdf';

class Download extends Component {

  render() {

    return (
        <div className = "btn-resume">
          <a href = {Pdf} target = "_blank">Resume</a>
        </div>

        
    );

  }
}

export default Download;