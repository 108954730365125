import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

const CarSite = () => {
    return (
        <div className='cards__container'>
         <div className='cards__wrapper2'>
         <ul className='cards__items'>
            <CardItem
                        src="images/car-site.jpg"
                        text="This is a Car rental site done in react."
                        label='Car Site'
                        path='/projects'
                        />
                        <div className='bio__copy'>
                        <h2>Car Rental Site!</h2>
                        <p>This is a car rental demo site that is created with react and using styled components. Created booking calendar and react carousel components.
                        <br /><br />
                        <div className='circle__icon'>
                        {/* <PlayCircleFilledIcon /> */}
                        <p className="live_site">
                        <a href="http://richyjaycodes.com/car-site" target="_blank"><span>Click Here For Live Site</span></a></p>
                        </div> 
                        
                        </p>
                        <br />
                        <h4>Skills:</h4>
                        <ul>
                            <li>React</li>
                            <li>JavaScript</li>
                            <li>CSS</li>
                            <li>Axios</li>
                            <li>API</li>
                            <li>Styled Components</li>
                        </ul>
                        </div>
                        
                        </ul>
            </div>
        </div>
    )
}

export default CarSite
