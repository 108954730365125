import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

const CardsGatsbySite = () => {
    return (
        <div className='cards__container'>
         <div className='cards__wrapper2'>
         <ul className='cards__items'>
            <CardItem
                        src="images/Gatsby-site1.jpg"
                        text="This is a Gatsby and Graphql Travel site."
                        label='Gatsby Travel Site'
                        path='/projects'
                        />
                        <div className='bio__copy'>
                        <h2>Travel Site!</h2>
                        <p>This travel site was created using Gatsby and Graphql and styled components. Smooth animations and a responsive mobile friendly and fast-laoding web app. 
                        <br /><br />
                        <div className='circle__icon'>
                        {/* <PlayCircleFilledIcon /> */}
                        <p className="live_site">
                        <a href="https://elastic-liskov-6b679e.netlify.app/" target="_blank"><span>Click Here For Live Site</span></a></p>
                        </div> 

                        </p>
                        <br />
                        <h4>Skills:</h4>
                        <ul>
                            <li>React</li>
                            <li>JavaScript</li>
                            <li>Gatsby</li>
                            <li>Graphql</li>
                            <li>Styled Components</li>
                    
                        </ul>
                        </div>
                        
                        </ul>
            </div>
        </div>
    )
}

export default CardsGatsbySite
