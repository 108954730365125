import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

const NetflixCard = () => {
    return (
        <div className='cards__container'>
         <div className='cards__wrapper2'>
         <ul className='cards__items'>
            <CardItem
                        src="images/netflix.jpg"
                        text="This is a Netflix Clone done in react."
                        label='Netflix Clone'
                        path='/projects'
                        />
                        <div className='bio__copy'>
                        <h2>Netflix Clone!</h2>
                        <p>This is a live Netflix clone that was created with react 
                        and javascript and axios that pulls data from an 
                        external database and puts the results on the 
                        apps home page. Nice rollover effects on the
                        individual cards that has seamless feel like the
                        real Netflix site.
                        <br /><br />
                        <div className='circle__icon'>
                        {/* <PlayCircleFilledIcon /> */}
                        <p className="live_site">
                        <a href="https://netflix-clone-69965.web.app//" target="_blank"><span>Click Here For Live Site</span></a></p>
                        </div> 
                        
                        </p>
                        <br />
                        <h4>Skills:</h4>
                        <ul>
                            <li>React</li>
                            <li>JavaScript</li>
                            <li>CSS</li>
                            <li>Axios</li>
                            <li>API</li>
                            <li>Firebase</li>
                        </ul>
                        </div>
                        
                        </ul>
            </div>
        </div>
    )
}

export default NetflixCard
