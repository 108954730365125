import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

const CardsAmazon = () => {
    return (
        <div className='cards__container'>
         <div className='cards__wrapper2'>
         <ul className='cards__items'>
            <CardItem
                        src="images/amazon-thumb.jpg"
                        text="Demo app of Amazon site done in react."
                        label='Amazon Clone'
                        path='/projects'
                        />
                        <div className='bio__copy'>
                        <h2>Amazon Clone!</h2>
                        <p>Amazon clone demo made to look just like the original.
                        Created with React and JavaScript and CSS. You can log in with your google id, add items to shopping cart and checkout page. 
                        <br /><br />

                        <div className='circle__icon'>
                        {/* <PlayCircleFilledIcon /> */}
                        <p className="live_site">
                         <a href="https://amazon-clone-one-gilt.vercel.app/" target="_blank"><span>Click Here For Live Site</span></a></p>
                        </div> 


                        </p>
                        <br />
                        <h4>Skills:</h4>
                        <ul>
                            <li>React</li>
                            <li>JavaScript</li>
                            <li>CSS</li>
                            <li>Axios</li>
                            <li>Firebase</li>
                            <li>Tailwind CSS</li>
                            <li>Redux</li>
                        </ul>
                        </div>
                        
                        </ul>
            </div>
        </div>
    )
}

export default CardsAmazon;
