import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

const CardsAirbnb = () => {
    return (
        <div className='cards__container'>
         <div className='cards__wrapper2'>
         <ul className='cards__items'>
            <CardItem
                        src="images/airbnb-thumb.jpg"
                        text="Demo app of Airbnb site done in react."
                        label='Airbnb Clone'
                        path='/projects'
                        />
                        <div className='bio__copy'>
                        <h2>Airbnb Clone!</h2>
                        <p>Airbnb clone demo made to look just like the original.
                        Created with React and JavaScript and CSS You can use the Search Feature and see the results page. You can also book a reservation using the date picker. 
                        <br /><br />

                        <div className='circle__icon'>
                        {/* <PlayCircleFilledIcon /> */}
                        <p className="live_site">
                        <a href="https://airbnb-clone-d3566.web.app//" target="_blank"><span>Click Here For Live Site</span></a></p>
                        </div> 


                        </p>
                        <br />
                        <h4>Skills:</h4>
                        <ul>
                            <li>React</li>
                            <li>JavaScript</li>
                            <li>CSS</li>
                            <li>Axios</li>
                            <li>Firebase</li>
                        </ul>
                        </div>
                        
                        </ul>
            </div>
        </div>
    )
}

export default CardsAirbnb
