import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import Skills from './components/pages/Skills';
import Projects from './components/pages/Projects';
import Resume from './components/pages/Resume';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';


function App() {
  return (
    <>
    <Router>
    <ScrollToTop>
      <Navbar />
      
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/skills' component={Skills} />
        <Route path='/projects' exact component={Projects} />
        <Route path='/resume' exact component={Resume} />
      </Switch>
      </ScrollToTop> 
      <Footer />
      
      </Router>
    </>
  );
}

export default App;
