import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

const GoogleClone = () => {
    return (
        <div className='cards__container'>
         <div className='cards__wrapper2'>
         <ul className='cards__items'>
            <CardItem
                        src="images/google-clone.jpg"
                        text="This is a Gmail clone done  in react."
                        label='Gmail clone'
                        path='/projects'
                />
                        <div className='bio__copy'>
                        <h2>Gmail Clone!</h2>
                        <p>Google Gmail clone that uses google authentication to login and logout. Built with redux and firebase for authenitcation and react and css for the look and feel.
                        <br /><br /></p>
                        
                        <div className='circle__icon'>
                        {/* <PlayCircleFilledIcon /> */}
                        <p className="live_site">
                        <a href="https://clone-e616b.web.app/" target="_blank"><span>Click Here For Live Site</span></a></p>
                        </div> 

                        
                        
                        <br />
                        <h4>Skills:</h4>
                        <ul>
                            <li>React</li>
                            <li>JavaScript</li>
                            <li>CSS</li>
                            <li>Redux</li>
                            <li>Firebase</li>
                        </ul>
                        </div>
                        
                        </ul>
            </div>
        </div>
    )
}

export default GoogleClone
