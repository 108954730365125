import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import { Button } from './Button';

const CardsBio = () => {
    return (
        <div className='cards__container'>
         <div className='cards__wrapper'>
         <ul className='cards__items'>
            <CardItem
                        src="images/img-bio.jpg"
                        text="Front End Web Developer - React | JavaScript | Node | Redux | SASS | Firebase | Tailwind | Gatsby | GraphQL"
                        label='Rich Jamison'
                        path='/'
                        />
                        <div className='bio__copy'>
                        <h2>Hi I'm Rich, Front End Web Developer</h2>
                        <p>After graduating college with a bachelors degree in Finance, I got a job as a Financial Consultant helping people buy their first homes, and invest in the stock market. Only, it became clear to me that I didn't have much passion and enjoyment working as a financial consultant. That is when I decided to switch careers and work in digital media.
                        </p>
                        <p>
                        I studied graphic design alongside finance in college, which then lead to print design, which lead to web design. I became proficient in Photoshop and Dreamweaver and started building websites. I soon discoverd that I loved to write code and make webpages come to life. I started hand coding html websites for local businesses while also learning SEO skills to get online traffic to their websites.
                        </p>
                        <p>For the past 5 years, I've learned and applied more advanced javascript, html, css and APi's for front end web development. Starting with Angular and Visual Studio to create web apps and then made the switch to using React and Visual Studio Code. I've developed a passion for front end web coding using react and javascript, and also google authentication for single page apps.
                        </p>
                        <p>
                        I'm constantly working on my web development skills daily to learn new stuff. I'm easily coachable and very reliable. 
                        </p>
                        <p>
                        <strong>Let’s build something cool.</strong><br />
<span className='span__bio'>Mail:</span> richyjaycodes@gmail.com<br />
<span className='span__bio'>Phone:</span> (405) 448-6537<br />
<span className='span__bio'>LinkedIn:</span> <span className="span__link"><a href="https://www.linkedin.com/in/rich-jamison/" target="_blank">https://www.linkedin.com/in/rich-jamison</a></span><br />
<span className='span__bio'>GitHub:</span> <span className="span__link"><a href="https://github.com/tabufx50" target="_blank">https://github.com/tabufx50</a></span>

                        </p>
                        
    <div className='bio-btns'>
        <Button
          className='btns'
          buttonStyle='btn--bio'
          buttonSize='btn--small'
        >
          RESUME
        </Button>
        
    </div>
                        
                        </div>
                        
                        
                        </ul>
            </div>
        </div>
    )
}

export default CardsBio
